<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent :max-width="maxWidth" :eager="eager">
      <v-card>
        <v-card-title v-if="$validate.DataValid(text)"> {{ text }} </v-card-title>
        <v-card-text>
          <slot v-if="!isDeleteDialog"></slot>
          <div v-else>{{ deleteDescription }}</div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            color="primary darken-1"
            text
            @click="dialogAction(false)"
            :loading="loading"
            :disabled="!enableAction"
          >
            取消
          </v-btn>
          <v-btn
            depressed
            color="error darken-1"
            text
            @click="dialogAction(true)"
            :loading="loading"
            :disabled="!enableAction"
          >
            確定
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: 'Dialog',
  props: {
    dialog: {
      type: Boolean,
      require: true,
      default: false,
    },
    text: {
      type: String,
      require: false,
      default: '',
    },
    deleteDescription: {
      type: String,
      require: false,
      default: '你是否要刪除此項目?',
    },
    isDeleteDialog: {
      type: Boolean,
      require: false,
      default: true,
    },
    maxWidth: {
      default: 600,
      require: false,
    },
    loading: {
      type: Boolean,
      require: false,
      default: false,
    },
    enableAction: {
      type: Boolean,
      require: false,
      default: true,
    },
    eager: {
      type: Boolean,
      require: false,
      default: false,
    },
  },
  methods: {
    dialogAction(action) {
      this.$emit('close', action)
    },
  },
}
</script>
