<template>
  <v-dialog max-width="400">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        depressed
        :text="textBtn"
        v-on="on"
        color="red darken-2 white--text"
        small
        class="px-6 mx-4"
        :class="btnClass"
        v-bind="attrs"
        :disabled="isFetching || disabled"
        >刪除</v-btn
      >
    </template>
    <template v-slot:default="dialog">
      <v-card class="py-3">
        <v-card-text class="d-flex flex-column justify-center align-center">
          <v-avatar class="mt-2" icon size="50">
            <v-icon size="3rem" color="error">{{ icons.mdiAlertCircleOutline }}</v-icon>
          </v-avatar>
          <span class="text-h6 mt-2">確定刪除?</span>
          <v-row class="mt-2">
            <v-col cols="6">
              <v-btn
                color="secondary"
                class="m-auto white--text form-btn"
                block
                x-large
                depressed
                @click.prevent="
                  dialog.value = false
                  $emit('removeAction', dialog)
                "
                >取消</v-btn
              >
            </v-col>
            <v-col cols="6">
              <v-btn
                color="error"
                class="m-auto white--text form-btn"
                block
                x-large
                depressed
                @click.prevent="$emit('removeAction', dialog)"
                >確定</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import { mdiAlertCircleOutline } from '@mdi/js'

export default {
  name: 'FormDeleteBtn',
  props: {
    btnClass: {
      type: Object,
      default: () => {},
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    textBtn: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  //props: ["removeAction", "btnClass"],
  setup() {
    return {
      icons: {
        mdiAlertCircleOutline,
      },
    }
  },
  computed: {
    ...mapState({
      isFetching: state => state.request.isFetching,
    }),
  },
}
</script>