var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-simple-table',{style:({ width: '100%' }),scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{style:({ 'min-width': _vm.tableData.length > 0 ? '100px' : '' })}),_vm._l((_vm.tableHeader),function(headerItem,index){return _c('th',{key:("header-" + index),staticClass:"text-left"},[_vm._v(" "+_vm._s(headerItem.text)+" ")])})],2)]),_c('draggable',_vm._b({attrs:{"tag":"tbody","handle":".handle","group":{ name: 'sortable-group' },"disabled":_vm.isFetching},model:{value:(_vm.tableData),callback:function ($$v) {_vm.tableData=$$v},expression:"tableData"}},'draggable',{
        animation: 200,
        group: 'sortable-group',
        disabled: false,
        ghostClass: 'first-level-ghost',
      },false),_vm._l((_vm.tableData),function(item,index){return _c('tr',{key:("table-item-" + index)},[_c('td',{staticStyle:{"width":"5%"}},[_c('v-icon',{staticClass:"handle"},[_vm._v(_vm._s(_vm.icons.mdiDragHorizontalVariant))])],1),_vm._l((_vm.tableHeader),function(headerItem,headerIndex){return [_c('td',{key:("td-" + headerIndex),style:({
              width: _vm.$validate.DataValid(headerItem.width) ? ("" + (headerItem.width)) : '',
              'min-width': _vm.$validate.DataValid(headerItem.minWidth) ? ("" + (headerItem.minWidth)) : '',
            })},[(headerItem.value === 'viewBtn')?[_c('v-btn',{attrs:{"depressed":"","text":"","color":"primary","small":"","disabled":_vm.isFetching},on:{"click":function($event){return _vm.$emit('dragTableAction', 'view', index)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiEye))]),_vm._v(" 查看 ")],1)]:(headerItem.value === 'editBtn')?[_c('v-btn',{attrs:{"depressed":"","text":"","color":"secondary","small":"","disabled":_vm.isFetching},on:{"click":function($event){return _vm.$emit('dragTableAction', 'edit', index)}}},[_vm._v(" 更改 ")])]:(headerItem.value === 'deleteBtn')?[_c('FormDeleteBtn',{attrs:{"textBtn":true},on:{"removeAction":function (action) {
                    if (action.value) {
                      _vm.$emit('dragTableAction', 'delete', index)
                    }
                    action.value = false
                  }}})]:(headerItem.value === 'slot')?[_vm._t(headerItem.slotName,null,{"index":index})]:(headerItem.value === 'duration')?[_vm._v(" "+_vm._s(_vm.getDuration(item[headerItem.value]))+" ")]:[_vm._v(" "+_vm._s(item[headerItem.value])+" ")]],2)]})],2)}),0)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }