<template>
  <div>
    <v-card rounded="lg" class="pa-5">
      <v-card-subtitle class="d-flex align-center">
        <div class="page-title mr-5">客戶評價</div>
        <v-btn color="primary" class="px-6" depressed small :disabled="isFetching" @click="addTestimonial">新增</v-btn>
      </v-card-subtitle>
      <v-card-text class="my-5">
        <DraggableTable
          :tableHeader="testimonialHeader"
          :data="testimonials"
          @setOrder="setTestimonialOrder"
          @dragTableAction="testimonialAction"
        ></DraggableTable>
      </v-card-text>
    </v-card>

    <CustomDialog
      :isDeleteDialog="false"
      :dialog="openTestimonialDialog"
      :text="testimonialDialogIndex === -1 ? '新增客戶評價' : '更改客戶評價'"
      @close="updateTestimonial"
      :eager="true"
    >
      <template v-slot>
        <v-form ref="testimonial-form">
          <v-row class="mt-3">
            <v-col cols="12">
              <form-control
                inputType="string"
                v-model="testimonialData.name"
                label="客戶名稱"
                :required="true"
                :disabled="isFetching"
              />
            </v-col>
            <v-col cols="12">
              <form-control
                inputType="textarea"
                v-model="testimonialData.description"
                label="評價"
                :required="true"
                :disabled="isFetching"
              />
            </v-col>
          </v-row>
        </v-form>
      </template>
    </CustomDialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import FormControl from '@/components/form/FormControl.vue'
import DraggableTable from '@/components/DraggableTable'
import CustomDialog from '@/components/CustomDialog.vue'

export default {
  name: 'TestimonialTable',
  props: {
    testimonialHeader: {
      type: Array,
      default: [],
      require: true,
    },
    testimonialsList: {
      type: Array,
      default: [],
      require: true,
    },
  },
  components: {
    FormControl,
    DraggableTable,
    CustomDialog,
  },
  data() {
    return {
      testimonials: [],
      openTestimonialDialog: false,
      testimonialDialogIndex: -1,
      testimonialData: {
        name: '',
        description: '',
      },
    }
  },
  computed: {
    ...mapState({
      isFetching: state => state.request.isFetching,
    }),
  },
  watch: {
    testimonialsList: {
      handler(val) {
        this.testimonials = JSON.parse(JSON.stringify(val))
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    addTestimonial(event, isAdd = true, index = -1) {
      if (isAdd) {
        this.testimonialData = {
          name: '',
          description: '',
        }
        this.testimonialDialogIndex = -1

        if (this.$validate.DataValid(this.$refs['testimonial-form'])) {
          this.$refs['testimonial-form'].reset()
        }
      } else {
        this.testimonialDialogIndex = index
        this.$set(this.testimonialData, 'name', this.testimonials[index].name)
        this.$set(this.testimonialData, 'description', this.testimonials[index].description)
      }
      this.openTestimonialDialog = true
    },
    testimonialAction(type, index) {
      if (type === 'edit') {
        this.addTestimonial(null, false, index)
      } else if (type === 'delete') {
        this.testimonials.splice(index, 1)
        this.$emit('update:testimonialsList', this.testimonials)
      }
    },
    updateTestimonial(action) {
      if (action) {
        const validate = this.$refs['testimonial-form'].validate()
        if (validate) {
          if (this.testimonialDialogIndex !== -1) {
            this.$set(this.testimonials[this.testimonialDialogIndex], 'name', this.testimonialData.name)
            this.$set(this.testimonials[this.testimonialDialogIndex], 'description', this.testimonialData.description)
          } else {
            this.testimonials.push({
              name: this.testimonialData.name,
              description: this.testimonialData.description,
            })
          }

          this.testimonialData = {
            name: '',
            description: '',
          }

          this.openTestimonialDialog = false

          this.$emit('update:testimonialsList', this.testimonials)
        }
      } else {
        this.openTestimonialDialog = false
      }
    },
    setTestimonialOrder(data) {
      this.testimonials = data
      this.$emit('update:testimonialsList', this.testimonials)
    },
  },
}
</script>
