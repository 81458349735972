<template>
  <div>
    <v-file-input
      v-show="false"
      :id="fileInputKey"
      :accept="fileFormatStr"
      outlined
      @change="getUploadFile($event, fileInputKey)"
      :disabled="isFetching"
    ></v-file-input>

    <v-row class="pa-0 ma-0" v-if="!showUploadTextField">
      <template v-if="!getFileObject && $validate.DataValid(uploadFile)">
        <!-- showUploadFileName -->
        <v-col
          cols="12"
          :xl="leftXl"
          :lg="leftLg"
          :md="leftMd"
          v-if="showUploadFileName"
          :class="{ 'ml-0 pl-0': removeLeftPadding }"
        >
          <a :href="`${$mediaPath}${uploadFile}`" target="_blank">{{ uploadFile }} </a>
        </v-col>

        <!-- showUploadPreviewImg -->
        <v-col
          cols="12"
          :xl="leftXl"
          :lg="leftLg"
          :md="leftMd"
          v-else-if="showUploadPreviewImg"
          :class="{ 'ml-0 pl-0': removeLeftPadding }"
        >
          <a :href="`${$mediaPath}${uploadFile}`" target="_blank">
            <v-img
              :max-height="imgMaxHeight"
              :max-width="imgMaxWidth"
              :contain="imgContain"
              :src="`${$mediaPath}${uploadFile}`"
            ></v-img>
          </a>
        </v-col>
      </template>

      <template v-if="getFileObject">
        <!-- showUploadPreviewImg -->
        <v-col
          cols="12"
          :xl="leftXl"
          :lg="leftLg"
          :md="leftMd"
          v-if="fileObj !== null || $validate.DataValid(vimeoLink)"
          :class="{ 'ml-0 pl-0': removeLeftPadding }"
        >
          <a
            :href="$validate.DataValid(vimeoLink) ? `${vimeoLink}` : 'javascript:void(0)'"
            :target="$validate.DataValid(vimeoLink) ? '_blank' : ''"
          >
            <v-img
              :max-height="imgMaxHeight"
              :max-width="imgMaxWidth"
              :contain="imgContain"
              :src="getFileObjImg()"
            ></v-img>
          </a>
        </v-col>
        <v-col
          cols="12"
          :xl="leftXl"
          :lg="leftLg"
          :md="leftMd"
          v-if="fileObj == null && !$validate.DataValid(vimeoLink) && $validate.DataValid(uploadFile)"
          :class="{ 'ml-0 pl-0': removeLeftPadding }"
        >
          <a
            :href="$validate.DataValid(uploadFile) ? `${$mediaPath}${uploadFile}` : 'javascript:void(0)'"
            :target="$validate.DataValid(uploadFile) ? '_blank' : ''"
          >
            <v-img
              :max-height="imgMaxHeight"
              :max-width="imgMaxWidth"
              :contain="imgContain"
              :src="getFileObjImg(true)"
            ></v-img>
          </a>
        </v-col>
      </template>

      <!-- upload btn -->
      <v-col
        cols="12"
        :xl="rightXl"
        :lg="rightLg"
        :md="rightMd"
        :class="{ 'align-self-end ml-0 pl-0': true, 'mr-0 pr-0': removeRightPadding }"
      >
        <v-btn
          class="px-6"
          small
          depressed
          color="primary"
          @click="triggerUploadFile(fileInputKey)"
          :disabled="isFetching"
        >
          {{ uploadText }}
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="pa-0 ma-0" v-else>
      <v-col
        cols="12"
        :xl="leftXl"
        :lg="leftLg"
        :md="leftMd"
        :class="{
          'ml-0 pl-0': removeLeftPadding,
          'pa-0 ma-0': removeAllPadding,
          'd-flex align-center flex-wrap': true,
        }"
      >
        <v-text-field
          class="pr-4"
          color="indigo darken-4"
          persistent-placeholder
          :value="uploadFile"
          :label="label"
          hide-details="auto"
          outlined
          readonly
          :error="$validate.DataValid(errorText)"
          :error-messages="errorText"
          :disabled="isFetching"
        ></v-text-field>

        <div class="d-flex align-center my-1">
          <v-btn
            class="px-4 mr-2"
            small
            depressed
            color="primary"
            @click="triggerUploadFile(fileInputKey)"
            :disabled="isFetching || readonly"
          >
            {{ uploadText }}
          </v-btn>

          <v-btn class="px-4" small depressed color="secondary" @click="previewImg" :disabled="isFetching">
            檢視
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'FormButtonUpload',
  props: {
    // get file object directly without upload
    getFileObject: {
      type: Boolean,
      default: false,
    },
    vimeoLink: {
      type: String,
      default: '',
    },
    uploadText: {
      type: String,
      default: '上載',
      require: false,
    },
    uploadFile: {
      type: String,
      default: '',
      require: true,
    },
    fileInputKey: {
      type: String,
      default: 'upload_file',
      require: false,
    },
    acceptFormat: {
      type: String,
      default: 'image',
      require: false,
    },
    showUploadFileName: {
      type: Boolean,
      default: true,
      require: false,
    },
    showUploadPreviewImg: {
      type: Boolean,
      default: false,
      require: false,
    },
    showUploadTextField: {
      type: Boolean,
      default: false,
      require: false,
    },
    maxFileSize: {
      type: Number,
      default: 10,
      require: false,
    },
    imgMaxHeight: {
      type: Number,
      default: 200,
      require: false,
    },
    imgMaxWidth: {
      default: undefined,
      require: false,
    },
    imgContain: {
      type: Boolean,
      default: false,
      require: false,
    },
    leftXl: {
      type: Number,
      default: 8,
    },
    leftLg: {
      type: Number,
      default: 8,
    },
    leftMd: {
      type: Number,
      default: 8,
    },
    rightXl: {
      type: Number,
      default: 4,
    },
    rightLg: {
      type: Number,
      default: 4,
    },
    rightMd: {
      type: Number,
      default: 4,
    },
    removeLeftPadding: {
      type: Boolean,
      default: false,
    },
    removeRightPadding: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    errorText: {
      type: String,
      default: '',
    },
    removeAllPadding: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    fileFormatStr: '',
    fileFormatArr: [],
    fileObj: null,
  }),
  methods: {
    ...mapActions(['setFetching'], 'request'),

    triggerUploadFile(id) {
      document.getElementById(id).click()
    },
    async getUploadFile(file, id) {
      if (file !== undefined && file !== null) {
        const check = this.$validate.validateFileInput(file, this.acceptFormat, this.fileFormatArr, this.maxFileSize)
        if (this.$validate.DataValid(check)) {
          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: check,
            type: 'error',
            refresh: false,
            redirect: '',
          })
        } else {
          if (this.getFileObject) {
            this.fileObj = file
            this.$emit('getFileObj', file)

            this.$forceUpdate()

            this.$store.dispatch('toggleAlertMessage', {
              show: true,
              message: '選擇成功',
              type: 'success',
              refresh: false,
              redirect: '',
            })
          } else {
            this.setFetching(true)

            let postData = {
              upload_image: { forceJPG: true },
              file,
            }

            if (this.$func.uploadDocumentType().includes(file.type)) {
              postData = {
                upload_document: {},
                file,
              }
            } else if (file.type === 'video/mp4' || file.type === 'video/webm') {
              postData = {
                upload_video: {},
                file,
              }
            }

            this.$XHR
              .post(postData, progress => {
                this.$store.dispatch('controlUploadAlert', {
                  show: true,
                  progress: this.$func.formatUploadProgress(progress.loaded, progress.total),
                })
              })
              .then(result => {
                this.$store.dispatch('controlUploadAlert')
                this.$func.log('--- upload success ---')
                this.$func.log(result)

                this.$store.dispatch('insertRemovedImage', result.data.filename)

                this.$emit('update:uploadFile', result.data.filename)

                this.$forceUpdate()

                this.$store.dispatch('toggleAlertMessage', {
                  show: true,
                  message: '上傳成功',
                  type: 'success',
                  refresh: false,
                  redirect: '',
                })

                this.setFetching(false)
              })
              .catch(error => {
                this.$store.dispatch('controlUploadAlert')
                this.$func.log('--- upload fail ---')
                this.$func.log(error)

                if (this.$validate.DataValid(error)) {
                  this.$store.dispatch('toggleAlertMessage', {
                    show: true,
                    message: error[1],
                    type: 'error',
                    refresh: false,
                    redirect: '',
                  })
                } else {
                  this.$store.dispatch('toggleAlertMessage', {
                    show: true,
                    message: '上傳失敗',
                    type: 'error',
                    refresh: false,
                    redirect: '',
                  })
                }

                this.$forceUpdate()

                this.setFetching(false)
              })
          }
        }
      }
    },
    previewImg() {
      if (this.$validate.DataValid(this.uploadFile)) {
        window.open(this.$mediaPath + this.uploadFile)
      } else {
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: '檔案不存在',
          type: 'error',
          refresh: false,
          redirect: '',
        })
      }
    },
    getFileObjImg(showImg = false) {
      if (this.acceptFormat === 'image') {
        if (showImg && this.$validate.DataValid(this.uploadFile)) {
          return `${$mediaPath}${this.uploadFile}`
        } else {
          return require('@/assets/empty-image.svg')
        }
      } else if (this.acceptFormat === 'video') {
        return require('@/assets/video.svg')
      }
    },
  },
  watch: {
    acceptFormat: {
      handler(val) {
        const format = this.$func.getAcceptFileFormat(val)
        this.fileFormatStr = format.formatStr
        this.fileFormatArr = format.formatArr
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState({
      isFetching: state => state.request.isFetching,
    }),
  },
}
</script>