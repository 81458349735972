<template>
  <v-simple-table :style="{ width: '100%' }">
    <template v-slot:default>
      <thead>
        <tr>
          <th :style="{ 'min-width': tableData.length > 0 ? '100px' : '' }"></th>
          <th class="text-left" v-for="(headerItem, index) in tableHeader" :key="`header-${index}`">
            {{ headerItem.text }}
          </th>
        </tr>
      </thead>
      <draggable
        v-model="tableData"
        tag="tbody"
        handle=".handle"
        :group="{ name: 'sortable-group' }"
        v-bind="{
          animation: 200,
          group: 'sortable-group',
          disabled: false,
          ghostClass: 'first-level-ghost',
        }"
        :disabled="isFetching"
      >
        <tr v-for="(item, index) in tableData" :key="`table-item-${index}`">
          <td style="width: 5%">
            <v-icon class="handle">{{ icons.mdiDragHorizontalVariant }}</v-icon>
          </td>

          <template v-for="(headerItem, headerIndex) in tableHeader">
            <td
              :style="{
                width: $validate.DataValid(headerItem.width) ? `${headerItem.width}` : '',
                'min-width': $validate.DataValid(headerItem.minWidth) ? `${headerItem.minWidth}` : '',
              }"
              :key="`td-${headerIndex}`"
            >
              <template v-if="headerItem.value === 'viewBtn'">
                <v-btn
                  depressed
                  text
                  color="primary"
                  small
                  @click="$emit('dragTableAction', 'view', index)"
                  :disabled="isFetching"
                >
                  <v-icon>{{ icons.mdiEye }}</v-icon>
                  查看
                </v-btn>
              </template>

              <template v-else-if="headerItem.value === 'editBtn'">
                <v-btn
                  depressed
                  text
                  color="secondary"
                  small
                  @click="$emit('dragTableAction', 'edit', index)"
                  :disabled="isFetching"
                >
                  更改
                </v-btn>
              </template>

              <template v-else-if="headerItem.value === 'deleteBtn'">
                <FormDeleteBtn
                  :textBtn="true"
                  @removeAction="
                    action => {
                      if (action.value) {
                        $emit('dragTableAction', 'delete', index)
                      }
                      action.value = false
                    }
                  "
                ></FormDeleteBtn>
              </template>

              <template v-else-if="headerItem.value === 'slot'">
                <slot :name="headerItem.slotName" :index="index"></slot>
              </template>

              <template v-else-if="headerItem.value === 'duration'">
                {{ getDuration(item[headerItem.value]) }}
              </template>

              <template v-else>
                {{ item[headerItem.value] }}
              </template>
            </td>
          </template>
        </tr>
      </draggable>
    </template>
  </v-simple-table>
</template>
<script>
import draggable from 'vuedraggable'
import { mdiDragHorizontalVariant, mdiEye } from '@mdi/js'
import FormDeleteBtn from '@/components/form/FormDeleteBtn'
import { mapState } from 'vuex'

export default {
  name: 'DraggableTable',
  order: 5,
  components: {
    draggable,
    FormDeleteBtn,
  },
  props: {
    tableHeader: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    return {
      icons: {
        mdiDragHorizontalVariant,
        mdiEye,
      },
    }
  },
  data() {
    return {
      list: [],
      dragging: false,
    }
  },
  computed: {
    ...mapState({
      isFetching: state => state.request.isFetching,
    }),

    tableData: {
      get() {
        if (this.data) {
          let result = this.data
          return result
        }
      },
      set(data) {
        this.$emit('setOrder', data)
      },
    },
  },
  methods:{
    getDuration(duration){
      const timeList = this.$func.translateToHourMinSec(duration);

      return `${timeList.hour > 0 ? timeList.hour + ':' : ''}${timeList.min >= 10 ? timeList.min : '0' + timeList.min}:${
          timeList.sec >= 10 ? timeList.sec : '0' + timeList.sec
        }`

    }
  }
}
</script>
<style scoped>
.handle {
  cursor: move;
  /* cursor: url(images/grab.cur); */
  cursor: grab;
}
.handle:active {
  /* cursor: url(images/grabbing.cur); */
  cursor: grabbing;
}

/* .button {
  margin-top: 35px;
} */
/* .handle {
  float: left;
  padding-top: 8px;
  padding-bottom: 8px;
} */

/* .close {
  float: right;
  padding-top: 8px;
  padding-bottom: 8px;
}

input {
  display: inline-block;
  width: 50%;
}

.text {
  margin: 20px;
} */
</style>